export enum Theme {
  default = 'default',
  large = 'large',
  incidentList = 'incidentList',
  contentList = 'contentList',
  twoColumn = 'twoColumn',
  oneColumn = 'oneColumn',
}

export interface Props {
  blue?: boolean,
  theme?: Theme,
  clean?: boolean,
  center?: boolean,
  centerItems?: boolean,
}
